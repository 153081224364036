import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}
  async canActivate() {
    const isLoggedIn = await this.authenticationService.isLoggedIn();
    if (isLoggedIn) {
      return true;
    }
    this.router.navigateByUrl('/login');
  }
}

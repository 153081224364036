import { Injectable } from '@angular/core';
import { AlertController, ToastController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    private loadingController: LoadingController) {}

  public async showInfoAlert(message: string) {
    const alert = await this.alertController.create({
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  public async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      position: 'bottom',
      duration: 2000
    });
    toast.present();
  }

  public async showLoading(message: string) {
    const loading = await this.loadingController.create({
      message,
      cssClass: 'sofiaxt-loading'
    });
    await loading.present();
    return loading;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { IframeComponent } from './iframe/iframe.component';
import { InputComponent } from './input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeroComponent } from './hero/hero.component';

@NgModule({
  declarations: [
    MenuComponent,
    HeaderComponent,
    HeroComponent,
    FooterComponent,
    IframeComponent,
    InputComponent
  ],
  exports: [
    MenuComponent,
    HeaderComponent,
    HeroComponent,
    FooterComponent,
    IframeComponent,
    InputComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ComponentsModule { }

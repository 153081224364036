import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './guards/authentication.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'favorites',
    loadChildren: () => import('./pages/favorites/favorites.module').then( m => m.FavoritesPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'store/plans',
    loadChildren: () => import('./pages/store/plans/plans.module').then( m => m.PlansPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'store/subscribe',
    loadChildren: () => import('./pages/store/subscribe/subscribe.module').then( m => m.SubscribePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'activities/homeworks',
    loadChildren: () => import('./pages/activities/homeworks/homeworks.module').then( m => m.HomeworksPageModule)
  },
  {
    path: 'activities/exams',
    loadChildren: () => import('./pages/activities/exams/exams.module').then( m => m.ExamsPageModule)
  },
  {
    path: 'activities/exercises',
    loadChildren: () => import('./pages/activities/exercises/exercises.module').then( m => m.ExercisesPageModule)
  },
  {
    path: 'activities/practices',
    loadChildren: () => import('./pages/activities/practices/practices.module').then( m => m.PracticesPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

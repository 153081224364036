import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideHeaderDirective } from './hide-header.directive';
import { IonicModule } from '@ionic/angular';
import { ShowHeaderDirective } from './show-header.directive';
import { AutoHeaderDirective } from './auto-header.directive';

@NgModule({
  declarations: [
    HideHeaderDirective,
    ShowHeaderDirective,
    AutoHeaderDirective,
  ],
  exports: [
    HideHeaderDirective,
    ShowHeaderDirective,
    AutoHeaderDirective,
  ],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class DirectivesModule { }

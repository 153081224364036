import { Directive, Input, ElementRef, Renderer2, OnInit, HostListener } from '@angular/core';
import { ScrollDetail } from '@ionic/core';
import { DomController } from '@ionic/angular';

@Directive({
  selector: '[appShowHeader]'
})
export class ShowHeaderDirective implements OnInit {

  @Input() header: HTMLElement;
  @Input() top?: number;

  public headerElement: HTMLElement;
  public topFlag: number;

  constructor(
      private renderer: Renderer2,
      private domController: DomController
    ) { }

  public ngOnInit() {
    this.headerElement = (this.header as any).el;
    this.topFlag = this.top !== undefined ? this.top : this.headerElement.clientHeight;
    this.domController.write(() => {
      this.renderer.setStyle(this.headerElement, 'webkitTransition', 'margin-top 400ms');
    });
    this.handleContentScroll(0);
  }

  @HostListener('ionScroll', ['$event']) onContentScroll(event: CustomEvent<ScrollDetail>) {
    this.handleContentScroll(event.detail.scrollTop);
  }

  private handleContentScroll(scrollTop: number) {
    if (scrollTop > this.topFlag) {
      this.domController.write(() => {
        this.renderer.setStyle(this.headerElement, 'margin-top', '0px');
      });
    } else {
        this.domController.write(() => {
          this.renderer.setStyle(this.headerElement, 'margin-top', `-${this.headerElement.clientHeight}px`);
        });
    }
  }

}

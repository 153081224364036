import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'heroStyle'
})
export class HeroStylePipe implements PipeTransform {

  transform(backgroundIndex: number = 1): object {
    return {
      background: `url('../../../assets/img/backgrounds/background-${backgroundIndex}.jpg')`,
      'background-attachment': 'fixed',
      'background-position': 'center',
      'background-repeat': 'no-repeat',
      'background-size': 'cover'
    };
  }

}

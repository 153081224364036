import { Component, OnInit } from '@angular/core';
import { UiService } from '../../services/ui.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  constructor(
    private uiService: UiService,
    private navController: NavController,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {}

  private async signOut() {
    const loading = await this.uiService.showLoading('Cerrando...');
    await this.authenticationService.signOut();
    this.navController.navigateRoot('/login');
    loading.dismiss();
  }

}

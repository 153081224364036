import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent implements OnInit {

  @Input() color?: string;
  @Input() image?: string;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() height?: string;
  @Input() parallax?: boolean;
  @Input() justify?: string;
  @Input() align?: string;
  @Input() direction?: string;

  public heroStyle: { [key: string]: any };

  constructor() { }

  public ngOnInit() {
    this.heroStyle = {};

    if (this.image) {
        this.heroStyle.background = `url('${this.image}')`;
        this.heroStyle.backgroundPosition = 'center';
        this.heroStyle.backgroundRepeat = 'no-repeat';
        this.heroStyle.backgroundSize = 'cover';
    } else if (this.color) {
        this.heroStyle.backgroundColor = `var(--ion-color-${this.color})`;
        this.heroStyle.color = `var(--ion-color-${this.color}-contrast)`;
    } else {
        this.heroStyle.backgroundColor = `var(--ion-color-light)`;
        this.heroStyle.color = `var(--ion-color-light-contrast)`;
    }

    this.heroStyle.height = this.height ? `${this.height}px` : '128px';
    this.heroStyle.justifyContent = this.justify ? this.justify : 'center';
    this.heroStyle.alignItems = this.align ? this.align : 'center';
    this.heroStyle.flexDirection = this.direction ? this.direction : 'row';

    if (this.parallax && this.image) {
        this.heroStyle.backgroundAttachment = 'fixed';
    }
  }

}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Storage } from '@ionic/storage';
import { ILoginUser, IResponse, ILoggedInUser } from '../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private loggedInUser: ILoggedInUser = null;

  constructor(private apiService: ApiService, private storageService: Storage) { }

  public async login(user: ILoginUser): Promise<boolean> {
    const response: IResponse = await this.apiService.userAuthenticate(user);
    if (response.error === false && response.data.succeeded === true) {
        this.loggedInUser = {
          userId: response.data.userId,
          token: response.data.token
        };
        await this.storageService.set('loggedInUser', this.loggedInUser);
        await this.storageService.set('isLoggedIn', true);
        return true;
    } else {
      await this.storageService.set('isLoggedIn', false);
      return false;
    }
  }

  public async signOut(): Promise<boolean> {
    await this.storageService.set('loggedInUser', null);
    await this.storageService.set('isLoggedIn', false);
    return true;
  }

  public async getLoggedInUser(): Promise<ILoggedInUser> {
    if (!this.loggedInUser) {
      this.loggedInUser = await this.storageService.get('loggedInUser') || null;
    }
    return this.loggedInUser;
  }

  public async isLoggedIn(): Promise<boolean> {
    const isLoggedIn = await this.storageService.get('isLoggedIn') || false;
    return isLoggedIn;
  }
}

import { Directive, OnInit, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
import { DomController } from '@ionic/angular';
import { ScrollDetail } from '@ionic/core';

@Directive({
  selector: '[appAutoHeader]'
})
export class AutoHeaderDirective implements OnInit {

  @Input() header: HTMLElement;

  public headerElement: HTMLElement;
  public lastTop: number;

  constructor(
      private renderer: Renderer2,
      private domController: DomController
    ) { }

  public ngOnInit() {
    this.headerElement = (this.header as any).el;
    this.domController.write(() => {
      this.renderer.setStyle(this.headerElement, 'webkitTransition', 'margin-top 400ms');
    });
  }

  @HostListener('ionScroll', ['$event']) onContentScroll(event: CustomEvent<ScrollDetail>) {
    console.log((event.target as any).clientHeight);
    if (event.detail.scrollTop > this.lastTop) {
      this.domController.write(() => {
        this.renderer.setStyle(this.headerElement, 'margin-top', `-${this.headerElement.clientHeight}px`);
      });
    } else {
      this.domController.write(() => {
        this.renderer.setStyle(this.headerElement, 'margin-top', '0');
      });
    }
    this.lastTop = event.detail.scrollTop;
  }

}

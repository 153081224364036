import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ILoginUser, IResponse, IMessage } from '../shared/interfaces';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  public userAuthenticate(user: ILoginUser): Promise<IResponse> {
    return new Promise((resolve) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      this.httpClient.post(`${apiUrl}/users/authenticate`, user, { headers })
        .subscribe((response: IResponse) => {
          resolve(response);
        });
    });
  }

  public userInfo(token: string): Promise<IResponse> {
    return new Promise((resolve) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      });
      this.httpClient.get(`${apiUrl}/users/info`, { headers })
        .subscribe((response: IResponse) => {
          resolve(response);
        });
    });
  }

  public userSendMessage(token: string, message: IMessage): Promise<IResponse> {
    return new Promise((resolve) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      });
      this.httpClient.post(`${apiUrl}/users/SendMessage`, message, { headers })
        .subscribe((response: IResponse) => {
          resolve(response);
        });
    });
  }
}
